<template>
  <layout>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-center">
        <b-button
          variant="secondary"
          class="icon-button back-button"
          @click="goToRequisitions"
        >
          <i class="bx bx-arrow-back font-size-16" />
        </b-button>
        <h1 class="title-1">FAQ</h1>
      </div>
      <div
        class="text-primary cursor-pointer font-size-18 pr-5"
      >
        <span :class="{ 'font-weight-bold': !isEng }" @click="isEng = false">Ru</span>
        /
        <span :class="{ 'font-weight-bold': isEng }" @click="isEng = true">En</span>
      </div>
    </div>
    <b-card>
      <div v-if="isEng">
        <div>
          <h2>What are Requisitions?</h2>
          <p>Requisitions are a section in the My Bimiboo (Pulse) system, serving as a centralized hub/tool for managing applications.</p>
          <p>Requisitions are divided into two types: Request and Purchase.</p>
          <ul>
            <li>Request - applications for compensation or selection of something (courses, equipment, conferences).</li>
            <li>Purchase - applications for the purchase of something (equipment, plugins, courses, etc.).</li>
          </ul>
        </div>
        <div class="mt-5">
          <h2>How to create a request?</h2>
          <div>
            <div>
              <span>1. One must go to the Requisitions page on the “My Bimi Boo” (BimiBoo) portal and click the “+ Add Requisition“ button.</span>
              <img src="@/assets/images/requisitions-faq/faq-1.png" />
            </div>
            <div>
              <span>
                2. In the pop-up modal window, select the Type and Category of the request you want to create: 
              </span>

              <h5 class="mt-4">Request:</h5>
              <ul>
                <li><i class="bx bxl-bitcoin text-warning  mr-1" /><b>Bimicoin Expenditure</b> - an application for exchanging Bimicoins.</li>
                <li><i class="bx bxs-book text-danger mr-1" /><b>Books & Publications</b> - an application for compensation for book purchases.</li>
                <li><i class="bx bx-swim text-primary mr-1" /><b>Fitness & Sport</b> - an application for compensation for fitness activities (gym, pilates, yoga, etc.)</li>
                <li><i class="bx bx-desktop text-info mr-1" /><b>Equipment & Hardware</b> - an application for selection and search of equipment (if you are not sure what equipment you need, we will search for you and provide several options).</li>
                <li><i class="bx bxs-chart text-primary mr-1" /><b>L&D Request</b> - an application for selection and search of educational courses (if you do not know which courses you need, we will provide several options).</li>
                <li><i class="bx bx-world text-success mr-1" /><b>Language Courses</b> - an application for compensation for language courses.an application for compensation for language courses.</li>
                <li><i class="bx bx-plus-medical text-danger mr-1" /><b>Medical Expenses</b> - an application for compensation for medical services.</li>
              </ul>

              <h5 class="mt-5">Purchases:</h5>
              <ul>
                <li><i class="bx bx-desktop text-info mr-1" /><b>Equipment & Hardware</b> - a request to purchase office equipment.</li>
                <li><i class="bx bx-laptop text-info mr-1" /><b>Equipment && Hardware Buyout</b> - a request to purchase equipment for work with subsequent buyout by the employee.</li>
                <li><i class="bx bxs-chart text-primary mr-1" /><b>L&D</b> - a request to purchase educational courses.</li>
                <li><i class="bx bx-money text-success mr-1" /><b>Other</b> - a request to purchase something related to professional activity, but not falling under the described categories.</li>
                <li><i class="bx bxs-joystick text-secondary mr-1" /><b>Plug-Ins for Unity</b> - a request to purchase plugins and licenses for Unity.</li>
                <li><i class="bx bx bxs-mouse-alt text-primary mr-1" /><b>Software & Applications</b> - a request to purchase software for professional activity.</li>
              </ul>
              <img src="@/assets/images/requisitions-faq/faq-2.png" />
            </div>
            <div>
              <span>3. Fill in the necessary information:</span>
              <ul>
                <li>In the Description field, specify the reason or justification.</li>
                <li>Pay attention to the red asterisks next to the field names - these fields are mandatory to fill in and may vary in different categories.</li>
                <li>In applications for compensation and exchange of Bimicoins, it is necessary to attach the purchase/payment receipt.</li>
              </ul>
              <div class="mt-4">
                <i class="bx bxs-error text-warning font-size-18" />
                Please note that applications in the categories: Fitness & Sport, Medical Expenses, Books & Publications, Language courses - are not available during the accounting close period (28th, 29th, 30th, 31st of each month).
              </div>
              <div class="my-4">
                <i class="bx bxs-error text-warning font-size-18" />
                For compensation for sports activities, medical services, educational courses, and book purchases, receipts must be presented no later than 30 days from the date of payment.
              </div>
            </div>
            <div>
              <span>After filling in the fields, press "Add".</span>
              <div>Congratulations! The request has been created. :)</div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <h2>How to check the status of my request?</h2>
          <p>On the “My Requisitions” tab, you can view the requests you have created.</p>
          <p>Check the current stage by looking at the "All Statuses" column or clicking on the photos in the "Stakeholders" column.</p>
          <p>In the pop-up window, you will see the details and status of your request, as well as comments from Stakeholders, if any were left.</p>
          <img src="@/assets/images/requisitions-faq/faq-3.png" />
        </div>
        <div class="mt-5">
          <h2>How to know if I need to approve a Requisition?</h2>
          <p>If you are a Stakeholder at any stage of the approval process, you can find out in two ways:</p>
          <ul>
            <li>When your approval is needed, you will receive a notification in Slack indicating the need for your participation.</li>
            <li>A tab named Waiting for Approval will appear on the Requisitions page.</li>
          </ul>
          <img src="@/assets/images/requisitions-faq/faq-4.png" />
        </div>
        <div class="mt-5">
          <h2>What to do if my participation is required?</h2>
          <p>If your approval is needed:</p>
          <ol>
            <li>Go to the Waiting for Approval tab;</li>
            <li>Click on the row of the desired Requisition;</li>
            <li>Study the details of the request in the pop-up window;</li>
            <li>
              <span>Make a decision, and leave a comment if necessary:</span>
              <ol type="I">
                <li>To approve, you need to click on the “Approve“ button.</li>
                <li>To decline, you need to click on the “Decline“ button.</li>
              </ol>
              <p class="mt-3">
                <i class="bx bxs-error text-warning font-size-18" />
                When making a decision, take into account the current procurement and compensation payment regulations in the company.
              </p>
            </li>
            <li>After a decision is made, the Requisition will disappear from the Waiting for Approval tab.</li>
          </ol>
          <img src="@/assets/images/requisitions-faq/faq-5.png" />
        </div>
      </div>
      <div v-else>
        <div>
          <h2>Что такое Requisitions?</h2>
          <p>Requisitions - раздел в системе My Bimiboo (Pulse), являющийся единым центром/инструментом для управления заявками.</p>
          <p>Requisitions разделены на два Type: Request и Purchase</p>
          <ul>
            <li>Request - заявки на компенсацию или подбор чего-либо (курсов, оборудования, конференций)</li>
            <li>Purchase - заявки на покупку чего-либо (оборудования, плагинов, курсов и тд.)</li>
          </ul>
        </div>
        <div class="mt-5">
          <h2>Как создать запрос?</h2>
          <div>
            <div>
              <span>1. Необходимо перейти на страницу Requisitions в портале “My Bimi Boo” ( BimiBoo ) и нажать на кнопку “+ Add Requisition“</span>
              <img src="@/assets/images/requisitions-faq/faq-1.png" />
            </div>
            <div>
              <span>
                2. Во всплывающем модальном окне выберите <b>Type</b> и <b>Category</b> запроса, который вы хотите завести:
              </span>

              <h5 class="mt-4">Request:</h5>
              <ul>
                <li><i class="bx bxl-bitcoin text-warning  mr-1" /><b>Bimicoin Expenditure</b> - заявка на обмен Bimicoins</li>
                <li><i class="bx bxs-book text-danger mr-1" /><b>Books & Publications</b> - заявка на компенсацию за покупку книг;</li>
                <li><i class="bx bx-swim text-primary mr-1" /><b>Fitness & Sport</b> - заявка на компенсацию за занятия спортом (тренажерный зал, пилатес, йога и тд.)</li>
                <li><i class="bx bx-desktop text-info mr-1" /><b>Equipment & Hardware</b> - заявка на подбор и поиск оборудования (если вы не уверены какое именно оборудование вам нужно, проведем поиск для вас и подберем несколько вариантов);</li>
                <li><i class="bx bxs-chart text-primary mr-1" /><b>L&D Request</b> - заявка на подбор и поиск обучающих курсов (если вы не знаете какие именно курсы вам нужны, мы подберем несколько вариантов);</li>
                <li><i class="bx bx-world text-success mr-1" /><b>Language Courses</b> - заявка на компенсацию за языковые курсы; Medical Expenses - заявка на компенсацию за медицинское услуги;</li>
                <li><i class="bx bx-plus-medical text-danger mr-1" /><b>Medical Expenses</b> - заявка на компенсацию за медицинское услуги.</li>
              </ul>

              <h5 class="mt-5">Purchases:</h5>
              <ul>
                <li><i class="bx bx-desktop text-info mr-1" /><b>Equipment & Hardware</b> - запрос на покупку офисного оборудования;</li>
                <li><i class="bx bx-laptop text-info mr-1" /><b>Equipment && Hardware Buyout</b> - запрос на покупку оборудования для работы с последующим выкупом сотрудником;</li>
                <li><i class="bx bxs-chart text-primary mr-1" /><b>L&D</b> - запрос на покупку обучающих курсов;</li>
                <li><i class="bx bx-money text-success mr-1" /><b>Other</b> - запрос на покупку чего-либо относящегося к профессиональной деятельности, но не подходящего под описанные категории;</li>
                <li><i class="bx bxs-joystick text-secondary mr-1" /><b>Plug-Ins for Unity</b> - запрос на покупку плагинов и лицензий для Unity;</li>
                <li><i class="bx bx bxs-mouse-alt text-primary mr-1" /><b>Software & Applications</b> - запрос на покупку программного обеспечения для профессиональной деятельности.</li>
              </ul>
              <img src="@/assets/images/requisitions-faq/faq-2.png" />
            </div>
            <div>
              <span>3. Заполните поля необходимой информацией:</span>
              <ul>
                <li>в поле <b>Description</b> укажите причину или обоснование;</li>
                <li>обратите внимание на <b>красные звёздочки</b> рядом с названиями полей - эти поля <b>обязательны</b> для заполнения и могут различаться в разных категориях.</li>
                <li>в заявках на <b>компенсацию</b> и обмен <b>Bimicoins</b> необходимо приложить <b>чек покупки/оплаты.</b></li>
              </ul>
              <div class="mt-4">
                <i class="bx bxs-error text-warning font-size-18" />
                Обращаем ваше внимание на то, что заявки по категориям: Fitness & Sport, Medical Expenses, Books & Publications,
                Language courses - <b>не доступны</b> в период закрытия месяца со стороны бухгалтерии (28, 29, 30, 31 числа каждого месяца).
              </div>
              <div class="my-4">
                <i class="bx bxs-error text-warning font-size-18" />
                Для компенсации расходов за спортивные занятия, медицинское обслуживание, образовательные курсы и покупку книг, чеки
                должны быть представлены не позднее чем 30 дней с момента совершения оплаты.
              </div>
            </div>
            <div>
              <span>4. После заполнения полей нажмите кнопку <b>“Add”</b></span>
              <div>Поздравляем! Запрос создан. :)</div>
            </div>
          </div>
        </div>
        <div class="mt-5">
          <h2>Как посмотреть статус моего запроса?</h2>
          <p>На вкладке <b>“My Requisitions”</b> вы можете просмотреть созданные вами запросы.</p>
          <p>Проверить текущий этап вы сможете посмотрев на колонку <b>“All Statuses”</b> или нажав на фотографии в колонке <b>“Stakeholders”</b>.</p>
          <p>Во всплывающем окне вы увидите детали и статус вашего запроса, а так же комментарии Stakeholders, если они были оставлены.</p>
          <img src="@/assets/images/requisitions-faq/faq-3.png" />
        </div>
        <div class="mt-5">
          <h2>Как узнать нужно ли мне согласовывать Requisition?</h2>
          <p>В случае если вы являетесь Stakeholder на каком-то из этапов согласования вы можете об этом узнать двумя способами:</p>
          <ul>
            <li>Когда понадобится ваше согласование, вам в Slack придет уведомление о необходимости вашего участия;</li>
            <li>У вас появится вкладка Waiting for Approval на странице Requisitions</li>
          </ul>
          <img src="@/assets/images/requisitions-faq/faq-4.png" />
        </div>
        <div class="mt-5">
          <h2>Что делать если необходимо моё согласование?</h2>
          <ol>
            <li>Перейти на вкладку Waiting for Approval;</li>
            <li>Нажать на строку нужного Requisition;</li>
            <li>Во всплывающем окне изучить детали запроса;</li>
            <li>
              <span>Принять решение, и оставить комментарий, если нужно:</span>
              <ol type="I">
                <li>Для согласования нужно нажать на кнопку “Approve“</li>
                <li>Для отмены нужно нажать на кнопку “Decline“</li>
              </ol>
              <p class="mt-3">
                <i class="bx bxs-error text-warning font-size-18" />
                Для принятия решения нужно учитывать текущий регламент закупок и оплаты компенсаций в компании.
              </p>
            </li>
            <li>После принятого решения Requisition пропадает из вкладки Waiting for Approval.</li>
          </ol>
          <img src="@/assets/images/requisitions-faq/faq-5.png" />
        </div>
      </div>
    </b-card>
  </layout>
</template>

<script>
export default {
  data() {
    return {
      isEng: false
    }
  },
  methods: {
    goToRequisitions() {
      this.$router.push({ name: 'requisitions' });
    },
  }
}
</script>

<style lang="scss" scoped>
ul, ol, p, span, div {
  font-size: 16px;
}

img {
  width: 100%;
  margin: 20px 0px;
  border-radius: 6px;
}

h5 {
  text-decoration: underline;
}
</style>
